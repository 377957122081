.login-container {
    min-width: 350px;
    min-height: 500px;
    height: 100vh;
    background: #F3F3F3;
    color: #414141;
    font-family: 'Futura Light';
    display: flex;
    flex-direction: column;
    align-items: center;

    form {
        max-width: 500px;
        width: 75%;
    }

    label {
        letter-spacing: -0.05em;
        font-weight: normal;
        font-size: 23px;
    }

    .btn {
        margin-left: auto;
        margin-right: auto;
        font-size: 23px;
        margin-top: 1em;
    }
}
