.published-document {
    position: absolute;
    right: 0;
    left: 0;
    float: left;
    clear: both;
    margin: 0 auto;
    padding: 10px 20px;
    min-height: 100%;
    max-width: 920px;
    width: 100%;
    height: 100%;
    height: auto;
    border: 5px solid #333;
    background: white;
    -ms-box-shadow: black 0 0 30px;
    -webkit-box-shadow: black 0 0 30px;
    box-shadow: black 0 0 30px;
    font-size: 1.2em;

    .document-content {
        position: relative !important;
    }
}

.fail-message {
    height:80vh;
    display:flex;
    justify-content:center;
    align-items:center;
    font-weight:700;
    font-size: 2rem;
}

@media(min-width: 992px) {
    .published-document {
        padding: 10px 80px;
    }
}
