/*========================
    fonts
========================*/
@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'OpenSansRegular';
    src: url("fonts/OpenSans-Regular-webfont.eot");
    src: url("fonts/OpenSans-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/OpenSans-Regular-webfont.woff") format("woff"), url("fonts/OpenSans-Regular-webfont.ttf") format("truetype"), url("fonts/OpenSans-Regular-webfont.svg#OpenSansRegular") format("svg");
}

@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'OpenSansLight';
    src: url("fonts/OpenSans-Light-webfont.eot");
    src: url("fonts/OpenSans-Light-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/OpenSans-Light-webfont.woff") format("woff"), url("fonts/OpenSans-Light-webfont.ttf") format("truetype"), url("fonts/OpenSans-Light-webfont.svg#OpenSansLight") format("svg");
}

@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'OpenSansBold';
    src: url("fonts/OpenSans-Bold-webfont.eot");
    src: url("fonts/OpenSans-Bold-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/OpenSans-Bold-webfont.woff") format("woff"), url("fonts/OpenSans-Bold-webfont.ttf") format("truetype"), url("fonts/OpenSans-Bold-webfont.svg#OpenSansBold") format("svg");
}

@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'OpenSansExtrabold';
    src: url("fonts/OpenSans-ExtraBold-webfont.eot");
    src: url("fonts/OpenSans-ExtraBold-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/OpenSans-ExtraBold-webfont.woff") format("woff"), url("fonts/OpenSans-ExtraBold-webfont.ttf") format("truetype"), url("fonts/OpenSans-ExtraBold-webfont.svg#OpenSansExtrabold") format("svg");
}

@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'OpenSansItalic';
    src: url("fonts/OpenSans-Italic-webfont.eot");
    src: url("fonts/OpenSans-Italic-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/OpenSans-Italic-webfont.woff") format("woff"), url("fonts/OpenSans-Italic-webfont.ttf") format("truetype"), url("fonts/OpenSans-Italic-webfont.svg#OpenSansItalic") format("svg");
}

@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'OpenSansLightItalic';
    src: url("fonts/OpenSans-LightItalic-webfont.eot");
    src: url("fonts/OpenSans-LightItalic-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/OpenSans-LightItalic-webfont.woff") format("woff"), url("fonts/OpenSans-LightItalic-webfont.ttf") format("truetype"), url("fonts/OpenSans-LightItalic-webfont.svg#OpenSansLightItalic") format("svg");
}

@font-face {
    font-weight: 400;
    font-style: normal;
    font-family: 'Futura Light';
    src: url(fonts/futurastd-light-webfont.eot?#iefix) format("embedded-opentype"), url(fonts/futurastd-light-webfont.woff) format("woff"), url(fonts/futurastd-light-webfont.ttf) format("truetype"), url(fonts/futurastd-light-webfont.svg#FuturaStdLightRegular) format("svg");
}

/*========================
    bootstrap overrides
========================*/
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    margin: 0 0 0;
    padding: 5px 0;
    padding-bottom: 0;
    -moz-min-width: 300px;
    -ms-min-width: 300px;
    -o-min-width: 300px;
    min-width: 300px;
    width: 300px;
    border: none;
    -ms-border-radius: 0;
    border-radius: 0;
    background-color: #fff;
    -moz-background-clip: padding-box;
    -ms-background-clip: padding-box;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -ms-box-shadow: inherit;
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
    list-style: none;
    font-size: 14px;
}

.datepicker.dropdown-menu {
    width: auto;
    width: inherit;
}

.modal-content {
    -ms-border-radius: 0;
    border-radius: 0;
}

.modal-title {
    font-family: 'Futura Light' !important;
    font-weight: normal !important;
    font-size: 20px !important;
    margin-top: 0 !important;
}

.alert {
    -ms-border-radius: 0;
    border-radius: 0;
}

.container {
    -moz-min-width: 980px;
    -ms-min-width: 980px;
    -o-min-width: 980px;
    min-width: 980px;
}

.button-group .btn {
    margin-right: 5px;
}

.form-control:focus {
    border-color: #888888;
    -ms-outline: 0;
    outline: 0;
    -webkit-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
}

.open > a {
    -ms-outline: none;
    outline: none;
}

.jqx-menu ul.dropdown-menu {
    top: 0;
    left: 0;
}

.form-group {
    margin-bottom: 10px;
}

.form-horizontal .control-label {
    text-align: left;
}

.btn {
    -ms-border-radius: 0;
    border-radius: 0;
}

.label {
    margin-right: 2px;
    padding: 0.6em 2em 0.6em .6em;
}

.label-fia {
    background-color: #b39463;
    color: white;
}

.label button.label-close {
    position: absolute;
    top: 10px;
    -ms-outline: none;
    outline: none;
    border: none;
    background: none;
    font-size: 15px;
    -ms-opacity: 0.5;
    opacity: 0.5;
}

/*========================
    main layout
========================*/
.container-full {
    margin: 0 auto;
    -moz-min-width: 980px;
    -ms-min-width: 980px;
    -o-min-width: 980px;
    min-width: 980px;
    width: 100%;
}

    .container-full .document-top .row, .container-full .document-footer .row {
        margin-left: 0;
    }

#loading {
    position: fixed;
    top: 35%;
    right: 0;
    left: 40%;
    z-index: 2000;
    width: 350px;
    height: 150px;
    -ms-border-radius: 0;
    border-radius: 0;
    background: white;
}

    #loading .details {
        margin: 10% auto;
        width: 200px;
        text-align: center;
        line-height: 40px;
    }

/*========================
    general branding
========================*/
body {
    font-weight: normal;
    font-size: 14px;
}

.logo {
    width: 100px;
    height: 85px;    
}

.logo-large {
    height: 250px;
    width: 141px;
    background: url("img/FIA-logo-tx.png") no-repeat center center;
}

a {
    -ms-outline: none;
    outline: none;
    text-decoration: none;
}

    a:hover {
        text-decoration: none;
        -ms-outline: none !important;
        outline: none !important;
    }

    a:active {
        text-decoration: none;
        -ms-outline: none !important;
        outline: none !important;
    }

    a:focus {
        text-decoration: none;
        -ms-outline: none !important;
        outline: none !important;
    }

/*========================
    login
========================*/
.login-background {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 75%;
    height: 100%;
}

    .login-background img {
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        height: 100%;
        min-width: 80%;
        max-width: inherit;
    }

.login.toolbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
}

.login-background .titles {
    position: absolute;
    top: 20px;
    right: 1%;
    text-align: right;
}

    .login-background .titles h1, .login-background .titles h2 {
        margin: 0;
        padding: 0;
        color: white;
        letter-spacing: -0.05em;
    }

    .login-background .titles h1 {
        font-size: 35px;
        font-family: 'Futura Light';
        line-height: 35px;
    }

    .login-background .titles h2 {
        font-size: 35px;
        font-family: 'Futura Light';
        line-height: 35px;
    }

.login-forgot {
    margin: 20px 0;
}

    .login-forgot a {
        color: #7d7d7d;
        letter-spacing: -0.01em;
        font-size: 13px;
        font-family: sans-serif;
    }

.login-version {
    position: absolute;
    bottom: 10px;
    left: 20px;
    color: #7d7d7d;
    letter-spacing: -0.01em;
    font-size: 13px;
    font-family: sans-serif;
}

.login-container.forgot-password-container {
    top: 15%;
    right: 33%;
    left: 33%;
    width: 600px;
    height: auto;
    -ms-border-radius: 10px;
    border-radius: 10px;
}

.forgot-password-container .logo.logo-large {
    margin-top: 20px;
    height: 110px;
}

    .forgot-password-container .logo.logo-large a {
        display: block;
        width: 100%;
        height: 100%;
    }

.forgot-password-container form {
    margin: 10px 10px 100px;
}

    .forgot-password-container form .form-submit {
        position: absolute;
        right: 146px;
        bottom: 20px;
    }

.login-background.forgot-password-background {
    width: 100%;
}

    .login-background.forgot-password-background img {
        -moz-min-width: 100%;
        -ms-min-width: 100%;
        -o-min-width: 100%;
        min-width: 100%;
        min-height: 110%;
        width: auto;
        height: 100%;
    }

/*========================
    main messaging view
========================*/
.toolbar {
    z-index: 1000;
    width: 100%;
    background: #001A4B;
    display: flex;
    flex-direction: row;
    flex: 1;
    color: white;
    font-size: 24px;
    font-family: 'Futura Light';
    justify-content: space-between;
    padding: 0.5em 1em;
    align-items: center;
}

    .toolbar h1 {
        margin: 20px 15px;
        padding: 0;        
        font-weight: normal;
        font-size: 24px;
        font-family: 'Futura Light';
        flex: 5;
    }

    .toolbar .back a {
        font-size:2em;
        text-decoration:none;
        color:white;
    }

    .toolbar .user-menu {
        padding-top: 13px;
        right: 0;
        letter-spacing: -0.01em;
        font-size: 15px;
        font-family: Arial;
    }

        .toolbar .user-menu .dropdown-menu > li > a, .toolbar .user-menu > .dropdown > a {
            display: block;
            padding: 5px 20px !important;
            width: 300px;
            text-align: left;
            text-transform: capitalize;
            font-size: 15px;
            -moz-transition: all 80ms ease;
            -ms-transition: all 80ms ease;
            -o-transition: all 80ms ease;
            -webkit-transition: all 80ms ease;
            transition: all 80ms ease;
        }

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus, .dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
    background-color: #001A4B;
    background-image: -moz-linear-gradient(top, #001A4B 0, #002160 100%);
    background-image: -webkit-gradient(linear, left 0, left 100%, from(#001A4B), to(#002160));
    background-image: -webkit-linear-gradient(top, #001A4B, 0, #002160, 100%);
    background-image: linear-gradient(to bottom, #001A4B 0, #002160 100%);
    background-repeat: repeat-x;
    color: white !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff001A4B',endColorstr='#ff002160',GradientType=0);
}

.toolbar .user-menu > .dropdown.open > ul > li > a {
    text-align: left;
}

.toolbar .user-menu > .dropdown > a {
    padding: 6px 20px !important;
    text-align: right;
    font-weight: normal;
}

.toolbar .user-menu > .dropdown.open > a {
    background: white;
    color: #002c5d;
}

.toolbar .user-menu > .dropdown > a i {
    position: relative;
    top: -2px;
    font-size: 15px;
}

#userMenu .sign-out-item button {
    margin-top: -9px;
    margin-left: 0;
    padding-left: 20px;
    width: 100%;
    height: 45px;
    -ms-outline: none;
    outline: none;
    border: none;
    background: none;
    text-align: left;
    font-weight: normal;
    font-size: 23px;
    font-family: 'Futura Light';
}

    #userMenu .sign-out-item button:hover {
        background: #95774c;
        color: white;
    }

#mainSplitter {
    margin-top: 66px;
}

.site-notification {
    position: fixed;
    bottom: 0;
    left: 23%;
    width: 60%;
    text-align: center;
}

.heading {
    margin: 0;
    padding: 10px 15px;
    background: #eaeaea;
}

    .heading h3 {
        margin: 0;
        padding: 0;
        color: #333;
        font-weight: normal;
        font-size: 24px;
        font-family: 'Futura Light';
        margin-top: 6px;
        overflow: hidden;
    }

#documents-panel .heading {
    overflow: auto;
}

.filter {
    width: 2em;
    height: 2em;
    background-size: contain;
    margin-left: 0.5em;
    cursor: pointer;
    font-style: normal;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

@media screen and (min-width: 1450px) {
    .filter {
        width: 2.5em;
        height: 2.5em;
    }
}

.filter .grade-stop-0 {
    stop-color: #01B4E1;
}

.filter .grade-stop-1 {
    stop-color: #003063;
}

.filter .inactive .grade-stop-0 {
    stop-color: #ccc;
}

.filter .inactive .grade-stop-1 {
    stop-color: #ccc;
}

.filter .envelope {
    fill: #ffffff;
}

.filter .is-new .envelope {
    fill: #b94a48;
}

.sidebar.right {
    position: absolute;
    top: 66px;
    right: 0;
    overflow: hidden;
    width: 50px;
    height: 100%;
    background: #ccc;
}

    .sidebar.right ul.conversations {
        display: block;
        clear: both;
        overflow-y: scroll;
        margin: 0;
        padding: 0;
        width: 67px;
        height: auto;
        border-top: 1px solid #f2f3f3;
    }

        .sidebar.right ul.conversations li {
            display: block;
            float: left;
        }

    .sidebar.right .conversation-toggle {
        width: 50px;
        height: 50px;
    }

        .sidebar.right .conversation-toggle .toggle-button {
            position: relative;
            top: 0;
            right: 0;
            z-index: 999;
            padding-top: 5px;
            border-bottom: 1px solid #eaeaea;
            background: #999;
            color: white;
            text-align: center;
            font-size: 31px;
            cursor: pointer;
            -moz-transition: all 300ms ease;
            -ms-transition: all 300ms ease;
            -o-transition: all 300ms ease;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
        }

            .sidebar.right .conversation-toggle .toggle-button.active {
                background: #002c5d;
            }

.conversation-window {
    position: fixed;
    top: 66px;
    right: -410px;
    z-index: 0;
    padding: 10px 20px;
    width: 380px;
    height: 93%;
    background: white;
    border-left: 1px solid #888;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .conversation-window.global-message-window {
        border-left: 2px solid #888;
        border-top: 1px solid #888;
        border-bottom: 1px solid #888;
        padding-top: 3px !important;
    }
}

.conversation-window.closing {
    position: static !important;
}

.conversation-window.animate {
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -ms-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    -ms-box-shadow: 0 0 7px #888;
    -webkit-box-shadow: 0 0 7px #888;
    box-shadow: 0 0 7px #888;
    border-left: none;
}

.conversation-window :after {
    -ms-content: "";
    content: "";
}

.conversation-window.active {
    right: 50px;
}

.conversation-wrapper {
    overflow-y: scroll;
    /*margin-top: 10px;
    margin-right: -19px;
    */
    /*WTF!! */
    position: relative;
    top: 9px;
    right: -19px;
    z-index: 1;
}

.conversation-window .heading {
    margin: -10px -20px;
}

.conversation-window h2, .conversation-window h3 {
    font-weight: normal;
    font-family: 'Futura Light';
}

.conversation-window .message p {
    color: #999;
}

.conversation-window .message {
    margin-right: 10px;
    padding-left: 10px;
    border-left: 5px solid #002c5d;
    cursor: pointer;
}

    .conversation-window .message.unread {
        margin-right: 10px;
        padding-left: 10px;
        border-left: 5px solid #b94a48;
        cursor: pointer;
    }

    .conversation-window .message.own-message {
        padding-left: 15px;
        border-right: 5px solid #002c5d;
        border-left: none;
        cursor: inherit;
    }

        .conversation-window .message.own-message.unread {
            margin-right: 10px;
            padding-left: 10px;
            border-right: 5px solid #b94a48;
            cursor: pointer;
        }

.conversation-window-close {
    position: absolute;
    top: 0;
    right: 5px;
    padding-top: 5px;
    padding-right: 5px;
    width: 20px;
    height: 20px;
    color: #333;
    text-align: right;
    font-size: 17px;
}

.conversation-window .conversation-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px 0;
    width: 100%;
    height: 225px;
    border-top: 1px solid #ccc;
    background: white;
}

    .conversation-window .conversation-controls textarea {
        margin: 0 auto;
        margin-bottom: 3%;
        margin-left: 3%;
        min-height: 140px;
        width: 94%;
    }

.conversation-controls .toolbar {
    position: absolute;
    top: inherit;
    bottom: 0;
    text-align: right;
    background: #b39463;
    height: 53px;
}

    .conversation-controls .toolbar button {
        margin: 10px 5px;
        -moz-min-width: 70px;
        -ms-min-width: 70px;
        -o-min-width: 70px;
        min-width: 70px;
        min-height: 30px;
        -ms-outline: none;
        outline: none;
        border: none;
        background: none;
        color: white;
    }

.sidebar.right ul.conversations li.global-message {
    position: absolute;
    bottom: 50px;
}

.global-message .conversation-window {
    right: -600px;
    height: auto;
    bottom: 50px;
    top: inherit;
    width: 600px;
    padding: 5px 10px 5px 30px;
}

    .global-message .conversation-window.active {
        right: 50px;
        height: auto;
        bottom: 50px;
        top: inherit;
        width: 600px;
    }

/*.sidebar.right .global-message .conversation-toggle .toggle-button.active {
    background: #b94a48;
}*/
.global-message .conversation-window-close {
    right: inherit;
    left: 5px;
}

/*========================
    document grid list
========================*/
.doc-grid-container .heading {
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 13px;
}

#docGridMenu {
    position: absolute;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 250px;
}

    #docGridMenu .dropdown-menu {
        width: 100%;
        position: static;
        height: auto;
        padding: 10px;
        border: 1px solid #EAEAEA;
        border-left: 1px solid #002C5D;
        min-width: inherit;
    }

        #docGridMenu .dropdown-menu li {
            margin: 5px 0;
            cursor: pointer;
            padding: 5px 0 5px 10px;
        }

            #docGridMenu .dropdown-menu li:hover {
                background: #dedede;
            }

#docGrid .doc-grid-container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding-bottom: 45px;
}

    #docGrid .doc-grid-container .doc-row {
        min-height: 70px;
        position: relative;
        width: 100%;
        margin: 0;
        cursor: pointer;
        margin-bottom: 1px;
    }

#docGrid .doc-row:hover {
    background-color: #dedede;
}

#docGrid .doc-row.last {
    margin-bottom: 45px;
}

#docGrid .doc-row.active {
    background-color: #dedede !important;
    /*white*/
    border-color: #002c5d !important;
    font-size: 13px;
    color: black !important;
    border-left: none !important;
}

    #docGrid .doc-row.active .doc {
        border-color: #002c5d !important;
        border-left: 4px solid;
    }

#docGrid .doc {
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    font-family: 'Futura Light';
    color: #414141;
    display:flex;
}

    #docGrid .doc .doc-details{
        flex-grow:1;
        display:flex;
        flex-direction:column;
    }

    #docGrid .doc .doc-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    #docGrid .doc .doc-icon {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        width: 60px;
        height: 100%;
        color: #333;
        vertical-align: middle;
        text-align: center;
        font-size: 47px;
        line-height: 42px;
    }

    #docGrid .doc .doc-sender {
        font-size: 25px;
    }

#docGrid .doc-row.active .doc .doc-sender {
    left: 6px;
}

#docGrid .doc .doc-subject {
    display: block;
    flex-grow: 1;
    text-transform: capitalize;
    font-size: 20px;
    font-family: Arial;
    word-break: break-word;
}

#docGrid .doc .doc-number {
    display: block;
    font-size: 16px;
}

#docGrid .doc .doc-date {
    width: 100px;
    text-align: right;
    white-space: normal;
    font-size: 16px;
}

#docGrid .doc.unread .doc-sender,
#docGrid .doc.unread .doc-date,
#docGrid .doc.unread .doc-subject,
#docGrid .doc.unread .doc-number,
#docGrid .doc.unread .doc-icon {
    color: #934b49;
}

#docGrid .doc.unread .doc-sender {
    left: 5px;
}

#docGrid .doc.unread {
    border-left: 4px solid #934b49;
}

    #docGrid .doc.unread, #docGrid .doc.unread * {
        color: #934b49;
    }

#docGrid .doc.recalled .doc-sender,
#docGrid .doc.recalled .doc-date,
#docGrid .doc.recalled .doc-subject,
#docGrid .doc.recalled .doc-number,
#docGrid .doc.recalled .doc-icon {
    color: #b94a48;
    text-decoration: line-through;
}

.doc-row {
    /*border-left: 8px solid white;*/
}

    .doc-row.active {
        border: none !important;
        border-left: 8px solid #001A4B !important;
        background: #eaeaea !important;
    }

        .doc-row.active .doc-icon, .doc-row.active .doc-icon {
            color: white;
        }

#document-viewer {
    height: 100%;
}

.document-wrapper {
    position: absolute;
    width: 100%;
}

.document-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    /*
    overflow-y: initial;
    overflow-x: hidden;
    */
}

    .document-container p.no-selection {
        position: absolute;
        top: 50%;
        right: 45%;
        left: 45%;
        color: #414141;
    }

.document-toolbar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: #001A4B;
}

    .document-toolbar ul {
        clear: both;
        margin: 6px 0 0;
    }

        .document-toolbar ul li {
            float: left;
            margin: 10px 15px;
        }

            .document-toolbar ul li a {
                display: block;
                padding: 0 10px;
                color: white;
                font-size: 15px;
            }

                .document-toolbar ul li a:hover {
                    margin: -1px;
                    border: 1px solid white;
                }

.heading.document-heading {
    padding: 10px 0;
    min-height: 100px;
    padding-left: 20px;
}

    .heading.document-heading.document-editor-heading {
        /*position: fixed;
        top: 0;
        right: 0;
        left: 0;*/
        z-index: 100;
        margin-left: 0;
        padding-top: 10px;
        width: 100%;
        height: auto;
        border-bottom: 1px solid #7d7d7d;
        background: white;
        font-size: 12px;
    }

        .heading.document-heading.document-editor-heading .document-editor-heading-wrapper {
            margin-left: 0;
        }

            .heading.document-heading.document-editor-heading .document-editor-heading-wrapper .form-group {
                margin: 0 0 5px;
                min-height: 30px;
            }

            .heading.document-heading.document-editor-heading .document-editor-heading-wrapper .control-label {
                padding-top: 8px;
                color: #7d7d7d;
            }

            .heading.document-heading.document-editor-heading .document-editor-heading-wrapper .col-xs-10 {
                padding-bottom: 1px;
                font-weight: bold;
            }

.document-heading .form-group {
    margin-bottom: 0;
}

.heading.document-heading .col-xs-6 .control-label {
    text-align: left;
}

.document-heading .alert {
    margin-top: 20px;
    margin-bottom: -10px;
    padding-left: 5%;
    width: 102%;
    border: none;
    -ms-border-radius: 0;
    border-radius: 0;
    background: #b39463;
    color: white;
    text-align: center;
    font-size: 20px;
    margin-left: -20px;
}

.document-logo {
    margin-top: 30px;
}

    .document-logo .topRight {
        float: right;
    }

.document-header {
    margin-top: 30px;
    width: 100%;
    text-align: center;
}

    .document-header h1 {
        font-family: Arial !important;
        /*font-size: 260%;*/
        text-align: center;
        /*padding-top: 10px;*/
        -webkit-font-variant: small-caps;
        -moz-font-variant: small-caps;
        -ms-font-variant: small-caps;
        -o-font-variant: small-caps;
        font-variant: small-caps;
        font-weight: bold;
    }

.document-content {
    float: left;
    clear: both;
    width: 100%;
}

    .document-content.editor {
        /*margin-top: 152px;
    margin-bottom: 100px;*/
        width: 80%;
    }

.document-top {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

    .document-top #topLeft {
        flex-grow: 4;
        min-height: 60px;
    }

    .document-top #topRight {
        flex-grow: 1;
    }

    .document-top .HeaderLabel {
        float: left;
        padding-right: 10px;
        width: 90px;
        text-align: left;
        font-weight: bold;
        margin-bottom: 6px;
        font-size: 80%;
    }

    .document-top .HeaderInput {
        float: left;
        margin: 0;
        width: 50%;
        margin-bottom: 6px;
        font-size: 80%;
    }

@media (min-width: 768px) {
    .document-top {
        flex-direction: row;
    }
}

.document-body {
    float: left;
    clear: both;
    margin-top: 20px;
    width: 100%;
    font-size: 80%;
}

    .document-body h1, .document-body h2, .document-body h3, .document-body h4 .document-body label {
        font-family: Arial, Helvetica, sans-serif !important;
        font-weight: bold;
    }

    .document-body h2 {
        font-size: 23px;
    }

    .document-body h3 {
        font-size: 18px;
    }

    .document-body p {
        font-family: Arial, Helvetica, sans-serif !important;
    }

    .document-body .form-horizontal .form-group {
        margin-left: 0;
    }

    .document-body table, .document-body table td {
        border-collapse: collapse !important;
        border: none !important;
    }

    .document-body .table thead > tr > th, .document-body .table tbody > tr > th, .document-body .table tfoot > tr > th, .document-body .table thead > tr > td, .document-body .table tbody > tr > td, .document-body .table tfoot > tr > td {
        line-height: 20px;
    }

.document-footer {
    float: left;
    clear: both;
    min-height: 150px;
    width: 100%;
    font-size: 14px;
    margin-top: 100px;
}

.modal-body .container-full {
    margin-top: -20px;
}

.document-footer #bottomLeft {
    width: 100%;
}

.document-footer #stewardList {
    float: left;
    clear: both;
    width: 100%;
}

    .document-footer #stewardList span {
        display: block;
        float: left;
        margin: 5px 0;
        width: 50%;
        text-transform: capitalize;
        font-weight: bold;
    }

.document-footer .stewardFooter {
    margin-top: 5px;
    font-weight: bold;
}

.col-sm-12.upload,
.col-sm-12.upload .control-label {
    padding-left: 0;
}

.btn-fia {
    border-color: #95774c;
    background-color: #95774c;
    color: #fff;
}

    .btn-fia:hover {
        border-color: #6d5738;
        background-color: #6d5738;
        color: white;
    }

/*===============
    Validation States
================*/
.field-validation-error {
    color: #b94a48;
    font-weight: bold;
}

/*===============
    Ajax and Modal Dialogs
================*/
.ajax-busy {
    position: fixed;
    top: 45%;
    left: 45%;
    width: 100px;
    height: 100px;
    -ms-border-radius: 10px;
    border-radius: 10px;
    background: black url(img/ajax-load-b.gif) no-repeat center center;
    -ms-opacity: 0.4;
    opacity: 0.4;
}

.modal-dialog-large {
    -moz-min-width: 600px;
    -ms-min-width: 600px;
    -o-min-width: 600px;
    min-width: 600px;
    width: 50%;
}

.autoModal.modal .modal-body {
    max-height: 600px;
}

.modal-dialog-x-large {
    -moz-min-width: 600px;
    -ms-min-width: 600px;
    -o-min-width: 600px;
    min-width: 600px;
    width: 80%;
}

.modal-content {
    display: grid;
}

.modal-body {
    /*overflow: auto;*/
}

.mce-widget.mce-tooltip {
    display: none !important;
}

.document-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.document-editor {
    padding: 5px 0;
}

    .document-editor .recipients {
        overflow-y: scroll;
        max-height: 750px;
    }

    .document-editor ul li div {
        margin-bottom: -5px;
        padding: 15px 30px;
        background: #eaeaea;
    }

    .document-editor h3 {
        margin: 0;
        padding: 10px 0;
        width: 140px;
        background: #001A4B;
        color: white;
        text-align: center;
        font-family: 'Futura Light';
    }

.document-recipient-list, .document-template-type-list {
    clear: both;
}

    .document-recipient-list .document-recipient, .document-template-type-list .document-template {
        position: relative;
        float: left;
        margin: 1px;
        width: 80px;
        height: 100px;
        border: 1px solid #001A4B;
        background: white;
        color: #001A4B;
        cursor: pointer;
    }

.document-recipient-box {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 15px;
    width: 100%;
    height: 50px;
    vertical-align: middle;
    text-align: center;
}

.document-recipient-list .document-recipient .document-recipient-name, .document-template .document-template-name {
    position: absolute;
    top: 50px;
    width: 100%;
    text-align: center;
}

    .document-recipient-list .document-recipient .document-recipient-name input[type=radio], .document-template .document-template-name input[type=radio] {
        clear: both;
        width: 100%;
        height: 18px;
    }

.editor-controls {
    /*position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 10px 0 0 0;*/
    padding: 20px 40px;
    width: 100%;
    height: 100px;
    max-height: 100px;
    border-top: 1px solid #888;
    background: white;
    text-align: right;
    z-index: 1;
    flex: 1;
}

.editor-wrapper {
    position: absolute;
    right: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    height: auto;
    background: white;
    display: flex;
    flex-direction: column;
}

    /*.editor-wrapper > div{flex:1;}*/
    #documentForm, .editor-wrapper form {
        /*margin: 0 auto;
        padding: 20px;
        width: 80%;*/
        height: 100%;
        background: white;
    }

/*===============
    User States
================*/
#users-panel .user-list.teams {
    padding-left: 30px;
}

.user-state i {
    vertical-align: middle;
    font-size: 23px;
}

.user-state .user-name {
    white-space: nowrap;
}

.user-state i.fa-mobile {
    font-size: 26px;
}

.user-list-dialog .user-state i, .user-list .user-state i {
    vertical-align: middle;
    font-size: 13px;
}

    .user-list-dialog .user-state i, .user-list .user-state i.fa-mobile {
        font-size: 17px;
        padding-right: 2px;
        padding-left: 2px;
    }

        .user-list-dialog .user-state i.fa-mobile {
            font-size: 20px;
            padding-left: 4px;
        }

.user-state.user-state-online {
    color: #2bb456;
}

.user-state.user-state-offline {
    color: #b94a48;
}

/*===============
   Bootstrap overides
================*/
.table thead > tr > th, .table tbody > tr > th, .table tfoot > tr > th, .table thead > tr > td, .table tbody > tr > td, .table tfoot > tr > td {
    vertical-align: middle;
}

.nav-tabs {
    border-bottom: 1px solid #f2f3f3;
}

    .nav-tabs > li {
        float: left;
        margin: 0;
        -moz-min-width: 170px;
        -ms-min-width: 170px;
        -o-min-width: 170px;
        min-width: 170px;
        width: 20%;
        border: 0;
        /*for main dashboard tabs*/
    }

        .nav-tabs > li > a {
            margin: 0;
            border: 0;
            -ms-border-radius: 0;
            border-radius: 0;
            color: #555;
            font-size: 20px;
            font-family: 'Futura Light';
            -ms-outline: none !important;
            outline: none !important;
        }

        .nav-tabs > li.active > a {
            background: #DEE1E5;
            -ms-outline: none !important;
            outline: none !important;
        }

        .nav-tabs > li:hover > a {
            background: #DEE1E5;
            -ms-outline: none !important;
            outline: none !important;
        }

        .nav-tabs > li.active > a,
        .nav-tabs > li.active > a:hover,
        .nav-tabs > li.active > a:focus {
            border: 0;
            background: #DEE1E5;
            background-color: transparent;
            -ms-outline: none !important;
            outline: none !important;
        }

.form-control {
    -ms-border-radius: 0;
    border-radius: 0;
}

.well {
    padding: 10px 0;
    border: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    background-color: transparent;
    background-image: none;
    -ms-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}

    .well .form-group {
        margin: 1px 0;
        padding: 5px 15px;
        background-color: #f2f3f3;
    }

    .well .form-horizontal .control-label {
        padding-left: 0;
        text-align: left;
    }

input.form-control[type="checkbox"],
input.form-control[type="radio"] {
    margin: 10px 0;
    width: auto;
    height: auto;
}

.alert-danger a {
    color: maroon;
}

    .alert-danger a:hover {
        color: #934b49;
    }

.alert-danger .btn-danger,
.alert-danger .btn-danger:hover,
.alert-danger .cancel {
    margin-right: 20px;
    color: #fff;
}

/*===============
    Site Wide Classes
================*/
.width-80 {
    width: 80%;
}

.width-60 {
    width: 60%;
}

.width-50 {
    width: 50%;
}

.width-40 {
    width: 40%;
}

.text-center {
    text-align: center;
}

.display-none {
    display: none;
}

.validation-summary {
    color: #b94a48;
    text-align: left;
}

.padding-top-10 {
    padding-top: 10px;
}

.document-status-table {
    width: 100%;
    font-size: 12px;
}

.padding-0-10 {
    padding: 0 10px;
}

.padding-0-20 {
    padding: 0 20px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-0-auto {
    margin: 0 auto;
}

/*===============
    Misc. Styles
================*/
.fa-calendar,
.input-group[data-datepicker="true"] {
    cursor: pointer;
}

#EntryList.table-condensed tbody > tr > td {
    border-top: 0;
    padding: 4px 8px;
}

.doc-grid-container::-webkit-scrollbar {
    width: 12px;
}

.doc-grid-container::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0;
    border-radius: 0;
    background: #001A4B;
    -webkit-box-shadow: none;
}

.doc-grid-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #EAEAEA;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.doc-meta-media {
    display: block;
}

.event-driver-modal {
    margin-right: 5px;
}

.document-frame {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
}

#document_body {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
}

.container-full {
    display: flex;
    flex-direction: column;
    flex: 1;
    /*align-items: stretch;*/
}

.editor-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    justify-content: space-between;
}

    .editor-wrapper form.editor-form {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        width: 100% !important;
        overflow: hidden;
        padding: revert !important;
        margin: revert !important;
    }

.document-content-container {
    width: 100%;
    flex: auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 4;
}

.document-content {
    flex-grow: 1;
    flex-direction: column;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    padding-top: 10px;
}

.document-body {
    flex-grow: 1;
    flex-direction: column;
    display: flex;
    padding-bottom: 20px;
}

.flexcolumn {
    flex-direction: column;
    display: flex;
}

.flexrow {
    flex-direction: row;
    display: flex;
}

.flex1 {
    flex: 1;
}

.flexgrow1 {
    flex-grow: 1;
}

.relativepos {
    position: relative;
}

.absolutepos {
    position: absolute;
}

.fixedpos {
    position: fixed;
}

.resizevertical {
    overflow: hidden;
    resize: vertical;
}

.zeroplacement {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.col-xs-1 .zeroplacement,
.col-xs-2 .zeroplacement,
.col-xs-3 .zeroplacement,
.col-xs-4 .zeroplacement,
.col-xs-5 .zeroplacement,
.col-xs-6 .zeroplacement,
.col-xs-7 .zeroplacement,
.col-xs-8 .zeroplacement,
.col-xs-9 .zeroplacement,
.col-xs-10 .zeroplacement,
.col-xs-11 .zeroplacement,
.col-xs-12 .zeroplacement,
.col-sm-1 .zeroplacement,
.col-sm-2 .zeroplacement,
.col-sm-3 .zeroplacement,
.col-sm-4 .zeroplacement,
.col-sm-5 .zeroplacement,
.col-sm-6 .zeroplacement,
.col-sm-7 .zeroplacement,
.col-sm-8 .zeroplacement,
.col-sm-9 .zeroplacement,
.col-sm-10 .zeroplacement,
.col-sm-11 .zeroplacement,
.col-sm-12 .zeroplacement,
.col-md-1 .zeroplacement,
.col-md-2 .zeroplacement,
.col-md-3 .zeroplacement,
.col-md-4 .zeroplacement,
.col-md-5 .zeroplacement,
.col-md-6 .zeroplacement,
.col-md-7 .zeroplacement,
.col-md-8 .zeroplacement,
.col-md-9 .zeroplacement,
.col-md-10 .zeroplacement,
.col-md-11 .zeroplacement,
.col-md-12 .zeroplacement,
.col-lg-1 .zeroplacement,
.col-lg-2 .zeroplacement,
.col-lg-3 .zeroplacement,
.col-lg-4 .zeroplacement,
.col-lg-5 .zeroplacement,
.col-lg-6 .zeroplacement,
.col-lg-7 .zeroplacement,
.col-lg-8 .zeroplacement,
.col-lg-9 .zeroplacement,
.col-lg-10 .zeroplacement,
.col-lg-11 .zeroplacement,
.col-lg-12 .zeroplacement {
    left: 15px;
}

.wh100 {
    width: 100%;
    height: 100%;
}

.minh240 {
    min-height: 240px;
}

.flexcolumn .ck.ck-editor,
.flexrow .ck.ck-editor {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    /*position: unset !important;*/
}

.flexcolumn .ck.ck-editor__main,
.flexrow .ck.ck-editor__main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /*following positioning will ensure scrollbars on the editor
        rather than the container
    */
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: 20px !important;
    overflow: hidden;
}

.resizevertical .flexcolumn .ck.ck-editor__main,
.resizevertical .flexrow .ck.ck-editor__main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /*following positioning will ensure scrollbars on the editor
        rather than the container
    */
    position: relative !important;
    margin-top: 0px !important;
    /*top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: 0px !important;
    overflow: hidden;*/
}

.flexcolumn .ck.ck-editor__top,
.flexrow .ck.ck-editor__top {
    /*needed because of absolut positioning and buttons were being chopped off*/
    z-index: 1;
}

.flexcolumn .ck.ck-editor__editable,
.flexrow .ck.ck-editor__editable {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: 20px !important;
}

.resizevertical .flexcolumn .ck.ck-editor__editable,
.resizevertical .flexrow .ck.ck-editor__editable {
    margin-top: 0px !important;
}

.resizevertical .flexcolumn .ck-font-family-dropdown .ck.ck-dropdown__panel.ck-dropdown__panel-visible,
.resizevertical .flexrow .ck-font-family-dropdown .ck.ck-dropdown__panel.ck-dropdown__panel-visible {
    max-height: 200px;
    overflow: auto;
}

.resizevertical .flexcolumn .ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel_se.ck-dropdown__panel-visible.ck-image-insert__panel,
.resizevertical .flexrow .ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel_se.ck-dropdown__panel-visible.ck-image-insert__panel {
    left: -100px;
}

.minh240 .ck.ck-editor__editable {
    min-height: 160px;
}

#team_members_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    flex: 1 1 auto;
    width: unset !important;
    height: auto !important;
    min-height: 34px;
}

    #team_members_list span {
        margin: 3px 3px;
        position: relative;
    }

        #team_members_list span.label button.label-close {
            top: 3px !important;
        }
